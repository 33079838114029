import React from "react"
import { Layout } from "../components/layout"

export default () => {
  return (
    <Layout style={{ padding: 30 }}>
      <div
        className="pb-3 no-home-header"
        style={{
          height: 116,
          backgroundSize: "cover",
        }}
      />
      <div style={{ marginTop: 160, marginBottom: 100 }} className="container">
        <meta content="text/html; charset=UTF-8" httpEquiv="content-type" />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "",
          }}
        />
        <p className="c1">
          <h3>Ecart.chat Privacy Policy</h3>
        </p>
        <p className="c1">
          <span className="c8">Ecart.chat "the App” provides </span>
          <span className="c10">
            ecart.chat helps track your store visitors journey in your shopify
            store and provide them the option of contacting you via WhatsApp.
            You will also be able to collect customer leads with our innovative
            wishlist widget
          </span>
          <span className="c0">
            &nbsp;"the Service" to merchants who use Shopify to power their
            stores. This Privacy Policy describes how personal information is
            collected, used, and shared when you install or use the App in
            connection with your Shopify-supported store.
          </span>
        </p>
        <p className="c1">
          <span className="c5">Personal Information the App Collects</span>
        </p>
        <p className="c1">
          <span className="c0">
            When you install the App, we are automatically able to access
            certain types of information from your Shopify account:
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            REST Admin API: To collect details about store, also using for
            Install and uninstall ecart.chat app
          </span>
        </p>
        <p className="c1">
          <span className="c0">Webhook API: To Receive events about </span>
        </p>
        <ul className="c6 lst-kix_7toro8f6ou0x-0 start">
          <li className="c1 c3">
            <span className="c0">Cart creation </span>
          </li>
          <li className="c1 c3">
            <span className="c0">Cart update</span>
          </li>
          <li className="c1 c3">
            <span className="c0">Checkout creation</span>
          </li>
          <li className="c1 c3">
            <span className="c0">Checkout Updation</span>
          </li>
        </ul>
        <p className="c1">
          <span className="c0">
            ScriptTag API : To inject app components to storefront &nbsp;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            Shop information like shop domain, currency, name and Product
            information like product id, product handle, price,
            &nbsp;Additionally, we collect the following types of personal
            information from you and/or your customers once you have installed
            the App:Information about you and others who may access the App on
            behalf of your store, such as your name, address, email address,
            phone number, and billing information; Information about individuals
            who visit your store, such as their IP address, web browser details,
            time zone, and information about the cookies installed on the
            particular device.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            We collect personal information directly from the relevant
            individual, through your Shopify account, or using the following
            technologies: “Cookies” are data files that are placed on your
            device or computer and often include an anonymous unique identifier.
            For more information about cookies, and how to disable cookies,
            visit http://www.allaboutcookies.org. “Log files” track actions
            occurring on the Site, and collect data including your IP address,
            browser type, Internet service provider, referring/exit pages, and
            date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic
            files used to record information about how you browse the Site. for
            improve our app performance we are tracking your actions using
            google analytics and hotjar
          </span>
        </p>
        <p className="c1">
          <span className="c5">How Do We Use Your Personal Information?</span>
        </p>
        <p className="c1">
          <span className="c0">
            We use the personal information we collect from you and your
            customers in order to provide the Service and to operate the
            App.Additionally, we use this personal information to: Communicate
            with you; Optimize or improve the App; and Provide you with
            information or advertising relating to our products or services.
          </span>
        </p>
        <p className="c1">
          <span className="c0">Sharing Your Personal Information</span>
        </p>
        <p className="c1">
          <span className="c0">
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, a search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            We use your information like email and name &nbsp;for remarket and
            advertise using social media marketing, email marketing, google
            adwords. &nbsp;Behavioural Advertising As described above, we use
            your Personal Information to provide you with targeted
            advertisements or marketing communications we believe may be of
            interest to you. For more information about how targeted advertising
            works, you can visit the Network Advertising Initiative’s (“NAI”)
            educational page at
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            Additionally, you can opt out of some of these services by visiting
            the Digital Advertising Alliance’s opt-out portal at:
            http://optout.aboutads.info/.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            Your Rights If you are a European resident, you have the right to
            access personal information we hold about you and to ask that your
            personal information be corrected, updated, or deleted. If you would
            like to exercise this right, please contact us through the contact
            information below.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            Data Retention When you place an order through the Site, we will
            maintain your Order Information for our records unless and until you
            ask us to delete this information.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            Changes We may update this privacy policy from time to time in order
            to reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            Contact Us For more information about our privacy practices, if you
            have questions, or if you would like to make a complaint, please
            contact us by e-mail at info@ecart.chat or by mail using the details
            provided below:
          </span>
        </p>
        <p className="c1 c9">
          <span className="c0" />
        </p>
        <p className="c2">
          <span className="c4" />
        </p>
      </div>
    </Layout>
  )
}
